import './styles/About.scss';

const About = () => {
  return <>
    <div className="animFromTop">

      <div>

        <div className='topGrid'>
          <div>

            <h1>About Me</h1>

            <h2>Michael Ballentine</h2>
            <h3>Loves the great outdoors</h3>
            <h4>Post-Modern Hardcore Metal</h4>
            <h5>Pad Thai Connoisseur</h5>
          
          </div>
          <div className="pfpdiv">
            <img className="pfp" src="pfp.jpg" />
            <h3 className="pfptxt">AI Image of Self</h3>
          </div>

        </div>

        <div className="griddy">
        
          <div className='info gwid'>

            <p>Hi there! My name is Michael and I am a Game Development, 3D Modeling, and Animation expert with a passion for creating immersive and engaging experiences. </p>
            <p>I am a confident problem solver and I have a diverse set of skills that allow me to tackle a wide range of projects, from 3D Modeling and World Building, development solutions, to PBR Texturing and more. I am also skilled in UX/UI design and have experience with advanced material editors and Unreal Niagara Particles. </p>
            <p>I created this site with the React framework with Typescript.</p>
            <p>Whether I'm creating a realistic environment or a fantastical world, I take pride in bringing my creations to life with attention to detail and a focus on user experience. </p>
            <p>What are we building next?!</p>

          </div>

          <div className='artist gwid'>

            <h3>Artist</h3>

            <h4>Software I use to create:</h4>
            <ul>
              <li>Unreal Engine 4 & 5</li>
              <li>Maya</li>
              <li>Adobe Suite - Photoshop | Premiere | Illustrator</li>
              <li>Substance Painter & Designer</li>
              <li>Magica Voxel</li>
            </ul>

            <h4>Proficient In:</h4>
            <ul>
              <li>3D Modeling</li>
              <li>Voxel Modeling</li>
              <li>World Building</li>
              <li>Texturing</li>
              <li>Animation and Rigging</li>
              <li>PBR Materials</li>
              <li>Advanced Materials</li>
              <li>Niagara Particle Effects</li>
              <li>UX / UI</li>
            </ul>

          </div>

          <div className='developer gwid'>

            <h3>Developer</h3>

            <h4>Stack:</h4>
            <ul>
              <li>Unreal Engine Blueprints</li>
              <li>Virtual Reality Development</li>
              <li>Game Development</li>
              <li>HTML | CSS | JS | TSX</li>
              <li>React | VUE</li>
              <li>THREE JS</li>
            </ul>

          </div>

          <div className='other gwid'>

            <h3>Other Creative Talents:</h3>

            <ul>
              <li>Video Editing</li>
              <li>SFX Editing</li>
              <li>Voiceovers</li>
            </ul>

          </div>
        
        </div>

      </div>

    </div>
{/* 
    -Unreal Engine 5 [World Building, Blueprints, Animation, Materials, VR]
    -Adobe [PS, PP, AE, XD]
    -Maya [Modeling, Rigging, Animation]
    -HTML, CSS, JS, PHP, 3JS
    -Audio [SFX, Voiceover, Editing] 
*/}
  </>;
};

export default About;