const Play = () => {
   
  return <div className="animFromTop">
          
          <h1>Play some of the games I've made.</h1>
          <h2>NOMAD</h2>
          <p>Credits: me</p>
          <iframe className="playFrame" src="https://itch.io/embed/1688143"><a href="https://metagrafox.itch.io/nomad">NOMAD</a></iframe>

          <h2>METAPHOR</h2>
          <p>Credits: me</p>
          <iframe className="playFrame" src="https://itch.io/embed/522544"><a href="https://metagrafox.itch.io/metaphor-alpha">METAPHOR</a></iframe>

          <h2>FLUSH</h2>
          <p>Credits: me, beefeastwood, parthnaik</p>
          <iframe className="playFrame" src="https://itch.io/embed/257609"><a href="https://vectre.itch.io/flush">FLUSH</a></iframe>

          <h2>Semisolidarity</h2>
          <p>Credits: me, beefeastwood</p>
          <iframe className="playFrame" src="https://itch.io/embed/597910"><a href="https://metagrafox.itch.io/semisolidarity">Semisolidarity</a></iframe>
          
          <h2>Breaking Faux News</h2>
          <p>Credits: me, beefeastwood, parthnaik</p>
          <iframe className="playFrame" src="https://itch.io/embed/295738"><a href="https://vectre.itch.io/breakingfauxnews">Breaking Faux News</a></iframe>

        </div>
};

export default Play;