const Contact = () => {
   
  return <div className="animFromTop">

            <h1>Contact</h1>

            <h2>Email me: michael@metagrafox.com</h2>

            <h3>or msg me on discord: metagrafox#4342</h3>

        </div>
};

export default Contact;