import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav className="nav">
          <Link to="/">Projects</Link>
          <Link to="/About">Bio</Link>
          <Link to="/Contact">Contact</Link>
          <Link to="/Play">Play</Link>  
      </nav>

      <div className="App"><Outlet /></div>
      
    </>
  )
};

export default Layout;