const Home = () => {
   
  return <div className="animFromTop">
          
          <div className="gwid">
            <h2>Various Projects by Michael Ballentine</h2>
            <h4>Game Dev and 3D Artist</h4>
          </div>
          <br/>

          <iframe src="https://www.youtube.com/embed/n6CwdAZpU6g" title="KRYPTO"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">KRYPTO - [Unreal Engine Jam Winner]</h2>
            <div className="gameDescription">
              <p>KRYPTO was a game made by myself and another person for the Unreal Engine Game Jam in 2019. This was the Marketplace Takeover Jam, where we were given less strict use on Epic Marketplace assets.</p>
              <p>We used a house asset and filled it with furniture to create an outpost system where the player would have to defeat fiat coins and return the boss koin to the firesafe to burn the fiat.</p>
              <p>For the character, I took an existing hand model from another project and modeled the mouth and teeth into it, and then built a rig and animated it. This was the most fun I've had taking a character from a static model to fully game-ready and implemented into gameplay.</p>
              <p>We ended up taking the W on that jam!</p>
            </div>
          </div>

          <iframe src="https://www.youtube.com/embed/ws-Aq7DMJCA" title="Nomad"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">NOMAD</h2>
            <div className="gameDescription">
              <p>Nomad is a procedurally generated sewer crawler built in Unreal Engine 5. The player can build a weapon before starting a run using a custom node based system. The gun scripting affects the trajectory of the bullets.</p>
              <p>The game was built in 7 days for the Unreal Engine Mega Jam 2022. Most of my time was spent building a node based scripting system using a node based scripting system[UE5 Blueprints]. I quickly constructed the sewer environment pieces and created some proc gen for the level that only loops back on itself in rare occasions..oops. I didn't have time to create a true aiming mechanic, so the player has to just aim in a cardinal direction and hope the bullets hit the enemies..oops. </p>
              <p>If you try out <button className="playBtn"><a href="https://metagrafox.itch.io/nomad">Nomad</a></button>.. 
              <ul>
                <li>AMP - Generates Numbers</li>
                <li>SINE - Modifies the input like a sinewave over time</li>
                <li>Compound - Multiplies the two inputs</li>
              </ul> 
              <ul>
                <li>Trigger - fire rate</li>
                <li>Barrel - spread</li>
                <li>Slide - unused and won't have any effect</li>
                <li>Grip - curl pattern</li>
              </ul>
              </p>
              <p>I used the game jam to push myself to build this prototype for a larger project that I have been working on. The core elements are here; node based weapon building, procedural dungeon generation, and shorter session-based gameplay.</p>
            </div>
          </div>

          <iframe src="https://www.youtube.com/embed/clvK0Pd6ACo" title="Metaphor"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">METAPHOR</h2>
            <div className="gameDescription">
              <p>Metaphor is a wave-based abilty game. Inspiration was derived from Risk of Rain 2. There are 4 waves of enemies including a final boss. This game was created in 7 days for the Unreal Engine Mega Jam 2019.</p>

              <p>Metaphor features health pick ups, 3 abilities, and 3 enemy types. There is a short cinematic before the game and one after using Sequencer.</p>

              <p>All elements in the game were created, programmed, animated, and textured by myself, with the exception of some MegaScans assets.</p>
            </div>
          </div>
          <iframe src="https://www.youtube.com/embed/izMOvciFkPM" title="Vectre"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">VECTRE VR</h2>
            <div className="gameDescription">
              <p>During my time with VECTRE, I played a vital role in designing, developing, and creating the experiences you'll see in this video.</p>
              <p>I absolutely love VR and am excited to continue to see the industry morph as technology advances.</p>
              <p>My favorite part of VR development is bringing things to life. Adding that little touch of magic that takes something from static polygons to a tangible interaction that borderline decives the user into thinking it's real.</p>
              <p>Some of the content shown is not VR. We used Unreal Engine Game Jams to advance our learning and decompress from our main projects.</p>
            </div>
          </div>
          <iframe src="https://www.youtube.com/embed/ICe3hP5bHZw" title="Voxy Bird"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">Voxy Bird</h2>
            <div className="gameDescription">
              <p>Voxy Bird was created for the Ouya Android console. Taking from the highly successful flappy bird concept, we built Voxy Bird with some extra features; powerups that let you barrel through the pipes for a few seconds and each pixel of the bird was subject to damage. This allowed a player to keep playing even if there was just a single pixel left of the bird. The game was wildly unsuccessful as was the Ouya but it was fun to build!</p>
            </div>
          </div>
          <iframe src="https://www.youtube.com/embed/EuKalCzQCiU" title="Tower Dive"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">Tower Dive</h2>
            <div className="gameDescription">
              <p>Tower Dive was an idea to help League of Legends players get a competitive edge. We created hundreds of questions and different question types to quiz players on detailed aspects of the game.</p>
              <p>One of the best features of the app was the timing questions. We trained players on the timing of different abilities. We saw dramatic improvements in play by the players who were beta testing the app.</p>
              <p>Unfortunately we were unable to continue development. Rip.</p>
            </div>
          </div>
          <iframe src="https://www.youtube.com/embed/KmpzuzfFdNU" title="CubeZ"></iframe>
          <iframe src="https://www.youtube.com/embed/LTTWJN0pfns" title="CubeZ Alpha"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">CubeZ</h2>
            <div className="gameDescription">
              <p>CubeZ was a third person, multiplayer, "destroy the brain to kill" concept. The game was developed by myself and one other person.</p>
              <p>CubeZ, pronounced Cube Z, was the first big game project that I worked on. I developed the original concept as a small endless zombie shooter, with the "brain-kill" mechanic. A friend really like the concept so we decided to build it out into a full multiplayer game.</p>
              <p>We started in Unity, and honestly, should have stayed in Unity.</p>
              <p>Unreal Engine 4 came out, a fancy new toy. We jumped on the opportunity, while also changing the art style to something that would ultimately kill the game, in my opinion.</p>
              <p>We had something special in Unity.</p>
              <p>Our game was stolen/hacked, we are still unsure to this day. But our Unity version of the game was being played by 40,000 players in Poland.</p>
              <p>We saw no benefit from this.</p>
              <p>After Unity killed their Web Player support, that killed the game on some of the sites that were hosting it, and we saw a large drop in Google Search, and other metrics.</p>
              <p>When we moved to Unreal Engine, we basically started over, but had the idea all ready to go. Just had to rebuild everything, figure out how to use UE4, and re-establish a player base. Easy! -_- </p>
              <p>We tried Humble Bundles and the like, but to no avail we couldn't produce the sales and income that we needed to continue development. My partner dropped the project, I didn't know how to code or manage the codebase at the time. And things kinda fell apart from there.</p>
              <p>I learned a lot from creating CubeZ.</p>
            </div>
          </div>
          <iframe src="https://www.youtube.com/embed/EOMFtRiY1ww" title="Plexus"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">Plexus</h2>
            <div className="gameDescription">
              <p>Plexus was the first full game that I built. It was a physics-ball-agilty game. You control a little ball and try to navigate the obstacles that rotate from the bottom of the satallite.</p>
              <p>The puzzle pieces randomly generated out of view and are destroyed the same way. Collecting gems increases the speed of the rotation and adds to your score.</p>
              <p>I used PlayMaker in Unity to visually script the entire thing.</p>
              <p>I later took the project further and put it on the Play Store on Android.</p>
              <p>We also launched this on Ouya while working on CubeZ, to help fund CubeZ development.</p>
            </div>
          </div>
          <iframe src="https://www.youtube.com/embed/hV_QmaSnHnQ" title="Because Humans"></iframe>
          <div className="gameInfo">
            <h2 className="gameTitle">Because Humans</h2>
            <div className="gameDescription">
              <p>Because Humans was built during one of the Unreal Engine Game Jams.</p>
              <p>You play as a polar bear with an ice sword trying to defend his icebergs from seals and other polar bears.</p>
              <p>The game could be played solo or with a friend on split screen.</p>
              <p>I animated the characters in theis game at 12fps to give it a stop-frame animation feel.</p>
            </div>
          </div>

        </div>
};

export default Home;