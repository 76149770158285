import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import Home from './Home';
import About from './About';
import NoPage from './NoPage';
import './styles/App.scss';
import Contact from './Contact';
import Play from './Play';
import { motion,useScroll } from "framer-motion";
import { Terrain1 } from './terrain1';

function clicky0() {
  document.documentElement.style.setProperty('--color1', '#b2faff');
  document.documentElement.style.setProperty('--color2', '#5ce5e5');
  document.documentElement.style.setProperty('--color3', '#39bfbf');
  document.documentElement.style.setProperty('--color4', '#238c8c');
  document.documentElement.style.setProperty('--color5', '#165955');
  document.documentElement.style.setProperty('--color6', '#0f332e');
  document.documentElement.style.setProperty('--color7', '#081a14');
  document.documentElement.style.setProperty('--color8', '#040d09');
}

function clicky1() {
  document.documentElement.style.setProperty('--color1', '#ffffff');
  document.documentElement.style.setProperty('--color2', '#ffd23e');
  document.documentElement.style.setProperty('--color3', '#b2d942');
  document.documentElement.style.setProperty('--color4', '#52c33f');
  document.documentElement.style.setProperty('--color5', '#166e7a');
  document.documentElement.style.setProperty('--color6', '#254d70');
  document.documentElement.style.setProperty('--color7', '#243046');
  document.documentElement.style.setProperty('--color8', '#152033');
}

function clicky2() {
  document.documentElement.style.setProperty('--color1', '#e3b47a');
  document.documentElement.style.setProperty('--color2', '#be794f');
  document.documentElement.style.setProperty('--color3', '#8d402f');
  document.documentElement.style.setProperty('--color4', '#5e1a20');
  document.documentElement.style.setProperty('--color5', '#182634');
  document.documentElement.style.setProperty('--color6', '#10182e');
  document.documentElement.style.setProperty('--color7', '#0c1023');
  document.documentElement.style.setProperty('--color8', '#060b11');
}

function clicky3() {
  document.documentElement.style.setProperty('--color1', '#e6ceac');
  document.documentElement.style.setProperty('--color2', '#cdba94');
  document.documentElement.style.setProperty('--color3', '#bda583');
  document.documentElement.style.setProperty('--color4', '#a48d6a');
  document.documentElement.style.setProperty('--color5', '#8b7d62');
  document.documentElement.style.setProperty('--color6', '#73654a');
  document.documentElement.style.setProperty('--color7', '#524839');
  document.documentElement.style.setProperty('--color8', '#292418');
}

function clicky4() {
  document.documentElement.style.setProperty('--color1', '#f6cd26');
  document.documentElement.style.setProperty('--color2', '#bb7f57');
  document.documentElement.style.setProperty('--color3', '#725956');
  document.documentElement.style.setProperty('--color4', '#ac6b26');
  document.documentElement.style.setProperty('--color5', '#563226');
  document.documentElement.style.setProperty('--color6', '#393939');
  document.documentElement.style.setProperty('--color7', '#2e2d25');
  document.documentElement.style.setProperty('--color8', '#202020');
}

function Component() {
  const { scrollYProgress } = useScroll();
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="About" element={<About />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="Play" element={<Play />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      
      <div>
        <div className='swatch s0' onClick={clicky0}></div>
        <div className='swatch s1' onClick={clicky1}></div>
        <div className='swatch s2' onClick={clicky2}></div>
        <div className='swatch s3' onClick={clicky3}></div>
        <div className='swatch s4' onClick={clicky4}></div>
      </div>

      <div>

        <Terrain1/>
        
        {/* <motion.div style={{ scaleX: scrollYProgress }}>
          <svg className="decor grass noclicks" viewBox="-17.557 394.305 523.61 113.558" xmlns="http://www.w3.org/2000/svg">
            <path d="M -17.557 506.013 L 506 507.863 L 506 394.305 L 453.963 406.847 L 407.774 458.693 L 326.137 456.23 L 192.644 430.187 L 91.805 450.524 L -7.264 454.374 L -17.557 506.013 Z" fill="var(--color4)"/>
          </svg>
        </div> */}

        <svg className="decor mid noclicks" viewBox="-19.853 291.21 528.66 215.395" xmlns="http://www.w3.org/2000/svg">
          <path d="M -19.853 216.594 L 508.807 216.002 L 508.807 431.397 L 449.696 376.737 L 439.078 321.256 L 391.944 274.897 L 351.445 280.387 L 285.756 258.76 L 159.519 279.472 L 80.639 252.489 L -19.853 259.616 L -19.853 216.594 Z" fill="var(--color6" transform="matrix(-1, 0, 0, -1, 488.954041, 722.606995)"/>
        </svg>

        <svg className="decor hill noclicks" viewBox="-19.853 216.002 528.66 290.603" xmlns="http://www.w3.org/2000/svg">
          <path d="M -20.311 396.155 L 508.349 395.563 L 508.349 686.166 L 468.857 669.655 L 476.224 548.231 L 436.719 469.717 L 390.226 469.213 L 313.092 426.876 L 202.66 432.329 L 100.89 421.15 L -20.311 411.928 L -20.311 396.155 Z" fill="var(--color8)" transform="matrix(-1, 0, 0, -1, 488.496002, 902.167969)"/>
        </svg>


      </div>


    </>

  );
}

export default App;
